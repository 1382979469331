<template>
  <div>
    <v-card flat>
      <v-card-text>
        <v-data-table
          :loading="loading"
          loading-text="Loading... Please wait"
          :headers="headers"
          :items="items"
          item-key="id"
          :items-per-page="10"
          :show-select="false"
          class="elevation-1 level1 templates"
          hide-default-footer
        >
          <template v-slot:top>
            <v-row class="headerTable-div">
              <v-col md="4" sm="12">
                <h3 class="custom-header main-color">
                  {{ $t("Announcements") }}
                </h3>
              </v-col>
              <!-- search and Filtes -->
              <v-col md="4" sm="12">
                <label class="search" for="inpt_search">
                  <input
                    id="inpt_search"
                    class="input_search"
                    @focus="inputFocus"
                    @blur="inputBlur"
                    type="text"
                    v-model="search"
                  />
                </label>
              </v-col>

              <v-col md="3" sm="12" class="text-right">
                <v-icon
                  class="main-color"
                  large
                  @click="add()"
                  :title="$t('Add New')"
                  >add_circle</v-icon
                >

                <!-- ================== Activity logs ================== -->
                <v-btn
                  icon
                  to="/systemlogging/announcements"
                  v-if="activitylogPermission"
                  target="_blank"
                  class=""
                >
                  <v-icon class="main-color" large :title="$t('System Log')"
                    >mdi-archive-clock</v-icon
                  >
                </v-btn>
              </v-col>
            </v-row>
          </template>
          <template v-slot:item.recipients="{ item }">
            <v-tooltip bottom max-width="1000px">
              <template v-slot:activator="{ on }">
                <p dark v-on="on">
                  {{ item.recipients ? item.recipients.slice(0, 30) : "" }}
                </p>
              </template>
              <span>{{ item.recipients }}</span>
            </v-tooltip>
            <!-- <p
              v-html="item.recipients ? item.recipients.slice(0, 30) : ''"
              :title="item.recipients"
            > -->
            <!-- {{  }} -->
            <!-- </p> -->
          </template>

          <template v-slot:item.actions="{ item }">
            <v-icon
              class="main-color"
              @click="editItem(item)"
              title="Edit"
              :id="item.id"
              >edit</v-icon
            >

            <v-icon
              class="main-color"
              :id="item.id"
              @click="openDia(item)"
              :title="$t('Delete')"
              >delete</v-icon
            >
          </template>
        </v-data-table>
        <!-- ======================================== Pagination ======================================  -->
        <div class="text-center pt-2">
          <v-pagination
            v-if="length > 0 && totalItems > 0"
            v-model="page"
            :length="length"
            :circle="circle"
            :page="page"
            :total-visible="totalVisible"
            next-icon="mdi-menu-right"
            prev-icon="mdi-menu-left"
          ></v-pagination>
        </div>
      </v-card-text>
    </v-card>
    <!-- snackText -->
    <v-snackbar
      v-model="snack"
      :timeout="snackTime"
      :color="snackColor"
      :right="true"
      :top="true"
    >
      <!-- {{ snackText }} -->

      <span class="white--text">{{ snackText }}</span>

      <v-btn text @click="snack = false">{{ $t("Close") }}</v-btn>
    </v-snackbar>

    <!-- Add edit Announcs item -->
    <v-dialog v-model="addEditDialog" persistent max-width="700">
      <v-card>
        <v-card-text>
          <v-row class="padding-top">
            <v-col cols="12" md="12">
              <v-card-title class="headline">{{
                $t("Announcements")
              }}</v-card-title>
            </v-col>
          </v-row>
          <v-container>
            <v-form ref="form" v-model="valid">
              <v-row v-if="!edit">
                <v-col cols="12" md="12">
                  <p v-if="errorRecipients" class="error--text text-center">
                    {{
                      $t("Can't send the message , please select recipient .")
                    }}
                  </p>
                </v-col>
                <!-- TO User -->
                <v-col cols="12" md="12">
                  <p class="value">
                    <strong class="custom-strong">{{ $t("To") }}:</strong>
                    <v-autocomplete
                      multiple
                      hide-selected
                      :items="allUsers"
                      @change="hideLabel = true"
                      item-text="name"
                      item-value="id"
                      v-model="itemDate.uesrs_id"
                      solo
                      chips
                    ></v-autocomplete>
                  </p>
                </v-col>
                <!-- To Class -->
                <v-col cols="12" sm="12" md="12">
                  <p class="value">
                    <strong class="custom-strong">{{ $t("To Class") }}:</strong>
                    <v-autocomplete
                      :items="classes"
                      item-text="name"
                      item-value="id"
                      v-model.trim="itemDate.classes_ids"
                      :multiple="true"
                      solo
                    ></v-autocomplete>
                  </p>
                </v-col>

                <!-- To Role -->
                <v-col cols="12" sm="12" md="12">
                  <p class="value">
                    <strong class="custom-strong">{{ $t("To Roles") }}:</strong>
                    <v-autocomplete
                      :items="roles"
                      item-text="name"
                      item-value="id"
                      v-model.trim="itemDate.roles_ids"
                      :multiple="true"
                      solo
                    ></v-autocomplete>
                  </p>
                </v-col>
              </v-row>
              <v-row v-else>
                <v-col cols="12" md="12">
                  <div>
                    <p class="value">
                      <strong>{{ $t("Recipients") }} : </strong>
                      {{ itemDate.recipients }}
                    </p>
                    <hr />
                  </div>
                </v-col>
              </v-row>

              <!-- <v-row>
                <v-col cols="12" sm="12" md="12">
                  <label for="Notes">{{ $t("Message") }}</label>

                  <vue-editor
                    v-model.trim="itemDate.message"
                    :editorToolbar="customToolbar"
                  >
                  </vue-editor>

                  <v-textarea
                    v-model="itemDate.message"
                    solo
                    :rules="[NotesRule(itemDate.message)]"
                  ></v-textarea>
                </v-col>
              </v-row> -->
              <!-- /message -->
              <v-row>
                <v-col cols="12" sm="12" md="12">
                  <label for="Notes">{{ $t("Message") }}</label>

                  <ckeditor
                    :editor="editor"
                    v-model="itemDate.message"
                    :config="editorConfig"
                  ></ckeditor>

                  <!-- <v-textarea
                    v-model="itemDate.message"
                    solo
                    :rules="[NotesRule(itemDate.message)]"
                  ></v-textarea> -->
                </v-col>
              </v-row>
              <!-- /message -->

              <!-- row Dates -->
              <v-row>
                <v-col cols="12" sm="6" md="6">
                  <label>{{ $t("From") }}</label>

                  <v-datetime-picker
                    solo
                    v-model="itemDate.from"
                    dateFormat="yyyy-MM-dd"
                    timeFormat="HH:mm:ss"
                    :rules="[
                      validationRules.required,
                      startDateTimeOrEqualRule(
                        itemDate.from,
                        itemDate.to,
                        $t(
                          'Start date should be less than or equal to end date'
                        )
                      ),
                    ]"
                    outlined
                    @keydown.prevent
                    @paste.prevent
                    @drop.prevent
                  ></v-datetime-picker>
                  <p class="error--text" v-if="startDateError">
                    {{ startDateError }}
                  </p>

                  <div v-if="empty_from_date">
                    <p class="red--text">{{ $t("This field is required") }}</p>
                  </div>
                </v-col>
                <!-- due date -->
                <v-col cols="12" sm="6" md="6">
                  <label>{{ $t("To") }}</label>
                  <v-datetime-picker
                    solo
                    v-model="itemDate.to"
                    dateFormat="yyyy-MM-dd"
                    timeFormat="HH:mm:ss"
                    :rules="[
                      validationRules.required,
                      startDateTimeOrEqualRule(
                        itemDate.from,
                        itemDate.to,
                        $t(
                          'Start date should be less than or equal to end date'
                        )
                      ),
                    ]"
                    outlined
                    @keydown.prevent
                    @paste.prevent
                    @drop.prevent
                  ></v-datetime-picker>
                  <p class="error--text" v-if="endDateError">
                    {{ endDateError }}
                  </p>

                  <div v-if="empty_from_date">
                    <p class="red--text">{{ $t("This field is required") }}</p>
                  </div>
                </v-col>
              </v-row>
              <!-- image -->
              <!-- <v-row>
                <v-col cols="12">
                  <label>{{ $t("Image") }}</label>
                </v-col>
                <v-col cols="12" md="12">
                  <div class="filesname">
                    <p class="files">{{ itemDate.fileNames }}</p>
                  </div>
                  <v-file-input
                    class=""
                    id="myFileBrowser"
                    style="display: none !important"
                    ref="attachment"
                    @change="readFiles"
                  ></v-file-input>
                  <v-btn icon @click="openFileInput()">
                    <v-icon class="main-color" style="cursor: pointer"
                      >attach_file</v-icon
                    >
                  </v-btn>
                  <p>{{ $t("Optimal Dimensions") }} 3200*410</p>
                </v-col>
              </v-row> -->
              <!-- Row Colors -->
              <v-row>
                <!-- Background Color -->
                <!-- <v-col cols="12" md="6">
                  <v-text-field
                    @focus="openBackColor = true"
                    v-model="itemDate.back_color"
                    @blur="openBackColor = false"
                    label="Background Color"
                  ></v-text-field>
                  <v-color-picker
                    v-model="itemDate.back_color"
                    class="ma-2"
                    v-if="openBackColor"
                    hide-inputs
                    hide-mode-switch
                  ></v-color-picker>
                </v-col> -->

                <!-- Font Color -->
                <!-- <v-col cols="12" md="6">
                  <v-text-field
                    @focus="openFontColor = true"
                    v-model="itemDate.font_color"
                    @blur="openFontColor = false"
                    label="Font Color"
                  ></v-text-field>
                  <v-color-picker
                    v-model="itemDate.font_color"
                    class="ma-2"
                    v-if="openFontColor"
                    hide-inputs
                    hide-mode-switch
                  ></v-color-picker>
                </v-col> -->
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn class="modal-btn-cancel" @click="closeAddEditDialog">{{
            $t("Close")
          }}</v-btn>
          <v-btn
            class="modal-btn-save"
            :loading="loading"
            :disabled="loading"
            @click="saveData"
            >{{ $t("Save") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Delete  -->
    <v-dialog
      v-model="dialogReset"
      persistent
      max-width="320"
      class="dialog_confirm"
    >
      <v-card>
        <v-card-title class="headline">{{
          $t("Confirmation Message")
        }}</v-card-title>

        <v-card-text>{{
          $t("Are you sure you want to Delete the Announcements?")
        }}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            class="modal-btn-cancel noBtn"
            @click="dialogReset = false"
            id="noBtn"
            ref="always_focus"
            >{{ $t("No") }}</v-btn
          >
          <v-btn
            id="yesBtn"
            @click="deleteItem(itemDate)"
            class="modal-btn-save yesBtn"
            >{{ $t("Yes") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";
// import { VueEditor } from "vue2-editor";
import { validationMixin } from "../mixins/validationMixin";
import ACL from "../acl";
import Editor from "../../ckeditor-build/build/ckeditor";

export default {
  name: "AnnouncementsComponent",
  mixins: [validationMixin],
  components: {
    // VueEditor,
  },
  data() {
    return {
      editor: Editor,
      editorConfig: {
        toolbar: {
          shouldNotGroupWhenFull: true,
        },
        simpleUpload: {
          uploadUrl: process.env.VUE_APP_API_URL + "/upload-ckeditor-image",
          headers: {
            "X-CSRF-TOKEN": "CSRF-Token",
            Authorization:
              "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImp0aSI6ImIwZDUyMDQ5NzA3YWM4MmMxNTFlMjhjOWYyODVjMzZiOWY4NDg0Y2Q2MDVkNjFlZTM0ZWVkMzYwMGUyNDk5NTU5YTc3OWFiNzczMGY1MjAwIn0.eyJhdWQiOiIxIiwianRpIjoiYjBkNTIwNDk3MDdhYzgyYzE1MWUyOGM5ZjI4NWMzNmI5Zjg0ODRjZDYwNWQ2MWVlMzRlZWQzNjAwZTI0OTk1NTlhNzc5YWI3NzMwZjUyMDAiLCJpYXQiOjE2ODEyMDg1NzAsIm5iZiI6MTY4MTIwODU3MCwiZXhwIjoxNjgyNDE4MTcwLCJzdWIiOiIxIiwic2NvcGVzIjpbXX0.iKKZr4VyPPL-p_2eMJeYt4pCBF-a6vwll38VonW9P97BkeDjl6cBm1K4h3YcQ5dyBKu1FnRaOxf6rK-vPAD6BrUKhXLATV14gjeHM-Pxy1aY1XTRJep-W1tNP2eNDjQWW8xRed1nkT5VHvpSQfKYju_dqj2VpjjNOvbK3GFHlvf4XOHX_1wK64wBjUbgrpGNTmdzkWBuE8IXb9E5RwRpOMSZaIywsws2gwCPk9MeED65B_8qFPaTrKWCl0K-G4CoSAUyIUzaaNGlwUhMrlUZHl2bKflJLINdMsj8RjKavLOLGAZpJLjUWkEJGf9-jEXV0MWtGBir0Et8G90UFFTlzFguRjiVIaIbQ_O-sRw-cgE8xi1_HgskDVzOQ9zZwWoWaTBN0rqQzK2sD_d8eJuPlpTlraM6yUSr9KnOLkcypUtsHQt5A2lPn8DpYdCwLHTVtub2UHBF1rl3ft-TwWrbO-tw6Kgl-cTYSw90zA09gUofrklB1fNn7_og1crcxNkmLPpGXIkkao3u-CM87_H--8HSltinvWjHP3dTcSJgRXTA8JjmTqVH-bEnH-q5twkkwVCTpDTlsY9uTFk6XnJ2aXOtcF-ZMxSe2bfuyIwecJc-OfAdAMNpaJUHqmJR7Xg4YY4rlYpC4JtzFsfqLlSD9KJWKA7y1xXPe4bL58Vc_JQ",
            enctype: "multipart/form-data",
          },
        },
      },

      // log activities permission
      activitylogPermission: ACL.checkPermission("activitylog"),
      // customToolbar: [
      //   ["bold", "italic", "underline"],
      //   [{ list: "ordered" }, { list: "bullet" }],
      //   [{ align: "" }, { align: "justify" }, { align: "right" }],
      //   ["link", "image"],
      // ],
      startDateError: "",
      errorRecipients: false,
      dialogReset: false,
      endDateError: "",
      openBackColor: false,
      openFontColor: false,
      loading: false,
      search: "",
      circle: true,
      page: 1,
      valid: true,
      length: "",
      totalVisible: "",
      totalItems: "",
      items: [],
      allUsers: [],
      classes: [],
      roles: [],
      itemDate: {
        message: "",
        img: "",
        uesrs_id: [],
        classes_ids: [],
        roles_ids: [],
        from: "",
        to: "",
        back_color: "",
        font_color: "",
        fileNames: "",
      },
      headers: [
        {
          text: this.$i18n.t("ID"),
          value: "id",
          sortable: false,
        },
        {
          text: this.$i18n.t("Message"),
          value: "message_list",
          sortable: false,
        },
        {
          text: this.$i18n.t("Recipients"),
          value: "recipients",
          sortable: false,
        },
        {
          text: this.$i18n.t("Date From"),
          value: "from",
          sortable: false,
        },
        {
          text: this.$i18n.t("Date To"),
          value: "to",
          sortable: false,
        },

        { text: this.$i18n.t("Actions"), value: "actions", sortable: false },
      ],
      addEditDialog: false,
      edit: false,

      menu: false,
      snack: "",
      snackTime: 3000,
      snackColor: "",
      snackText: "",
      empty_from_date: false,
      empty_to_date: false,
    };
  },
  watch: {
    page: function () {
      this.getAllData(this.page);
    },

    search: {
      handler() {
        this.page = 1;
        this.getAllData(this.page);
      },
      deep: true,
    },
  },
  methods: {
    openDia(item) {
      this.itemDate = item;
      this.dialogReset = true;
    },
    readFiles(File) {
      if (File) {
        this.itemDate.fileNames = File.name;
        const selectedImage = File;

        this.createBase64ImageForAttachments(selectedImage);
      }
    },
    createBase64ImageForAttachments(fileObject) {
      const reader = new FileReader();
      const _this = this;
      reader.onload = (e) => {
        const img = e.target.result;
        _this.itemDate.img = img;
      };
      reader.readAsDataURL(fileObject);
    },
    add() {
      this.addEditDialog = true;
    },
    editItem(item) {
      this.itemDate = item;
      this.edit = true;
      this.addEditDialog = true;
    },
    closeAddEditDialog() {
      this.itemDate = {};
      this.addEditDialog = false;
      window.location.reload();
    },
    saveData() {
      if (!this.edit) {
        if (
          !this.itemDate.from ||
          !this.itemDate.to ||
          this.startDateError != "" ||
          this.endDateError != ""
        ) {
          if (!this.itemDate.from) {
            this.empty_from_date = true;
          }
          if (!this.itemDate.to) {
            this.empty_to_date = true;
          }
        } else if (
          this.itemDate.uesrs_id.length == 0 &&
          this.itemDate.classes_ids.length == 0 &&
          this.itemDate.roles_ids.length == 0
        ) {
          this.errorRecipients = true;
        } else {
          this.empty_from_date = false;
          this.empty_to_date = false;
          if (!this.valid) {
            this.$refs.form.validate();
          } else {
            this.loading = true;

            // send api save

            axios
              // .post(this.getApiUrl + "/announcements/store", formData, {
              .post(this.getApiUrl + "/announcements/store", this.itemDate, {
                headers: {
                  Authorization: "Bearer " + localStorage.token,
                },
              })
              .then((response) => {
                if (response.data.status.error != true) {
                  window.location.reload();
                  this.addEditDialog = false;
                }
              });
          }
        }
      } else {
        this.loading = true;

        // send api save

        axios
          .post(
            this.getApiUrl + "/announcements/update/" + this.itemDate.id,
            this.itemDate,
            {
              headers: {
                Authorization: "Bearer " + localStorage.token,
              },
            }
          )
          .then((response) => {
            if (response.data.status.error != true) {
              window.location.reload();
              this.addEditDialog = false;
            }
          });
      }
    },
    getAllData(page = null) {
      if (page == null) {
        page = this.page;
      }
      axios
        .get(
          this.getApiUrl +
            "/announcements?search=" +
            this.search +
            "&page=" +
            page,
          {
            headers: {
              Authorization: "Bearer " + localStorage.token,
            },
          }
        )
        .then((response) => {
          this.setPaginationParameters(response);
          this.totalItems = response.data.data.total;
          this.items = [];
          this.items = response.data.data.data;
          this.loading = false;
        });
    },
    getRecipients() {
      axios
        .get(this.getApiUrl + "/announcements/recipients", {
          headers: {
            Authorization: "Bearer " + localStorage.token,
          },
        })
        .then((response) => {
          this.allUsers = response.data.data.users;
          this.classes = response.data.data.classes;
          this.roles = response.data.data.roles;
        });
    },
    deleteItem(item) {
      axios

        .get(this.getApiUrl + "/announcements/destroy/" + item.id, {
          headers: {
            Authorization: "Bearer " + localStorage.token,
          },
        })
        .then((response) => {
          if (response.data.status.error != true) {
            this.dialogReset = false;
            this.snack = true;
            this.snackColor = "green";
            this.snackText = response.data.status.message;
            this.getAllData(this.page);
            window.location.reload();
          }
        });
    },
    setPaginationParameters(response) {
      this.length = response.data.data.last_page;
      if (this.length > 5) this.totalVisible = 5;
    },
    inputFocus() {
      $("#inpt_search").parent("label").addClass("active");
      $(".input_search").parent("label").addClass("active");
    },
    inputBlur() {
      if (this.search == "") {
        $("#inpt_search").parent("label").removeClass("active");
        $(".input_search").parent("label").removeClass("active");
      }
    },
    NotesRule(notes) {
      if (notes != "" && notes != undefined && notes != null) {
        if (notes.length > 300) {
          return "This field must be less than 100 characters";
        } else {
          return true;
        }
      } else {
        return true;
      }
    },
    dateRequiredRulePeriod(date) {
      if (date.length < 1) {
        return "This field is required";
      } else {
        return true;
      }
    },
    startDateRule(start, end) {
      var startDate = moment(start, "DD/MM/YYYY");
      var endDate = moment(end, "DD/MM/YYYY");
      return startDate >= endDate
        ? "Start date should be less than end date"
        : true;
    },
    openFileInput() {
      document.getElementById("myFileBrowser").click();
    },
    // readFiles(file) {
    //   this.itemDate.fileNames = file.name;

    //   this.itemDate.img = file;
    //   // }
    // },
  },

  mounted() {
    this.getAllData(this.page);
    this.getRecipients();
  },
};
</script>

<style scoped></style>
